import {useAddToCart as coreUseAddToCart} from "@shopware-pwa/composables-next";
import type {Schemas} from "~/api-types/storeApiTypes";
import {getProductFromPrice} from "@shopware-pwa/helpers-next";

export function useAddToCart(product: Ref<Schemas["Product"]>) {
  const coreFunctionality = coreUseAddToCart(product);
  const {apiClient} = useShopwareContext();
  const {cartItems} = useCart();

  const addToCart = async () => {
    if (cartItems.value.length > 0) {
      await apiClient.invoke("deleteCart delete /checkout/cart", {})
    }

    return await coreFunctionality.addToCart();
  };

  const fromPrice = getProductFromPrice(product.value);

  const showButton = computed(() =>
    !fromPrice && !product.value.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.isCompleted
  );

  return {
    ...coreFunctionality,
    addToCart,
    showButton,
  };
}
